<template functional>
    <div v-show="props.visible" class="loader-circle__container">
        <div class="loader-circle">
            <span class="loader"></span>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        visible: {
            type: Boolean,
            required: true
        }
    }
}
</script>

<style lang="scss">
.loader-circle__container {
    background-color: rgba(0, 0, 0, 0.5);
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: 100%;
    z-index: 99999999999999999999;
    display: flex;

    .loader-circle {
        width: 100%;
        height: 100%;
        display: flex;

        .loader {
            width: 85px;
            height: 85px;
            border: 10px solid rgba(15, 169, 88, 0.1);
            border-bottom-color: #007f32;
            border-radius: 50%;
            display: flex;
            box-sizing: border-box;
            animation: rotation 1s linear infinite;
            margin: auto;
        }

        @keyframes rotation {
            0% {
                transform: rotate(0deg);
            }
            100% {
                transform: rotate(360deg);
            }
        }
    }
}
</style>
