const errorMessageCode = (code) => {
    switch (code) {
        case 100:
            return 'Wybrana apteka jest zablokowana. Zmień aptekę i ponów zamówienie.'
        case 200:
            return 'Wybrany produkt został zablokowany lub jest nieaktywny.'
        case 210:
            return 'Wybrana apteka nie ma uprawnień do sprzedaży produktów wyróżnionych poniżej.'
        case 220:
        case 230:
            return 'Zaznaczone niżej produkty są chwilowo niedostępne.'
        case 400:
            return 'Przepraszamy. Cena oznaczonego produktu uległa zmianie. Sprawdź poniżej aktualną cenę.'
        case 401:
            return 'Cena oznaczonego produktu nie została odnaleziona.'
        case 410:
            return 'Poziom refundacji oznaczonego produktu uległ zmianie.'
    }
}

export default {
    computed: {
        ORDER_WITHOUT_UNAVAILABLE: () =>
            'Możesz kontynuować składając zamówienie z ich pominięciem.',
        PRODUCT_TEMPORARY_UNAVAILABLE: () => 'Produkt chwilowo niedostępny',
        PRODUCT_PRICE_CHANGED: () => 'Cena produktu uległa zmianie'
    },
    methods: {
        sendErrorsToGtm(orderData, category) {
            let productsErrors = {}
            if (orderData.order_data) {
                for (const [sku, product] of Object.entries(
                    orderData.order_data
                )) {
                    if (!product.available && !product.priceChanged) {
                        productsErrors[sku] = this.PRODUCT_TEMPORARY_UNAVAILABLE
                    }
                    if (product.priceChanged) {
                        productsErrors[sku] = this.PRODUCT_PRICE_CHANGED
                    }
                }
            }

            if (orderData?.errors) {
                Array.from(orderData.errors).forEach((error) => {
                    window.GoogleAnalyticsUniversal.prototype.errorSend({
                        category: category,
                        action: this.prepareErrorAction(error.message),
                        additionalData: {
                            sku: error.sku,
                            message: productsErrors[error.sku]
                        }
                    })
                })
            }

            if (orderData?.error && orderData.order_data.message) {
                window.GoogleAnalyticsUniversal.prototype.errorSend({
                    category: 2,
                    action: this.prepareErrorAction(
                        orderData.order_data.message
                    )
                })
            }
        },
        prepareErrorAction(error) {
            if (typeof error == 'string') {
                return error
            }
            return errorMessageCode(error)
        }
    }
}
