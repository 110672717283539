var render = function(_h, _vm) {
  var _c = _vm._c
  return _c(
    "div",
    {
      directives: [
        {
          name: "show",
          rawName: "v-show",
          value: _vm.props.visible,
          expression: "props.visible"
        }
      ],
      staticClass: "loader-circle__container"
    },
    [_vm._m(0)]
  )
}
var staticRenderFns = [
  function(_h, _vm) {
    var _c = _vm._c
    return _c("div", { staticClass: "loader-circle" }, [
      _c("span", { staticClass: "loader" })
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }