<template>
    <LoaderCircle :visible="isLoading"></LoaderCircle>
</template>
<script>
import axios from 'axios'
import { debounce } from 'lodash'
import cartUtils from './mixins'
import gtm from '../gtm-mixin.js'
import LoaderCircle from '../utils/LoaderCircle.vue'

export default {
    components: { LoaderCircle },
    scopeName: 'qtycart',
    mixins: [cartUtils, gtm],
    data() {
        return {
            counter: 0,
            priceTags: null,
            total: null,
            discount: null,
            totalTop: null,
            totalCount: null,
            proceedBtn: null,
            serverQty: {}, // quantities from server
            serverCustomerActive: {}, // customer active from server
            currentQty: {}, // client-side quantities
            currentCustomerActive: {}, // client-side customer active
            customerActiveAllCheckbox: {}, // client-side customer active all
            expectedServerQty: {}, // expected quantities as a server result
            expectedCustomerActive: {}, // expected quantities as a server result
            requestsToSend: [],
            serverIsBusy: false,
            productForms: null,
            qtyFormInputs: null,
            customerActiveCheckbox: null,
            selectedProduct: [],
            removeSelectedLi: null,
            removeUnselectedLi: null,
            isLoading: false
        }
    },
    computed: {
        debouncedUpdate() {
            return debounce(this.UpdateCartQuotes, 350)
        },

        changedProducts() {
            return Object.keys(this.expectedServerQty)
                .filter(
                    (productId) =>
                        this.currentQty[productId] !==
                            this.expectedServerQty[productId] ||
                        this.currentCustomerActive[productId] !==
                            this.expectedCustomerActive[productId]
                )
                .map((productId) => ({
                    product: productId,
                    qty: this.currentQty[productId],
                    customerActive: this.currentCustomerActive[productId]
                }))
        }
    },
    mounted() {
        const mainForm = document.querySelector('[data-main-form]')
        this.qtyFormInputs = document.querySelectorAll('[data-qtyForm-input]')
        this.customerActiveCheckbox = document.querySelectorAll(
            '.customer_active_checkbox'
        )
        this.customerActiveAllCheckbox = document.querySelector(
            '.customer_active_all_checkbox'
        )

        this.productForms = document
            .getElementById('product-forms')
            .querySelectorAll('form')

        this.productForms.forEach((form) => {
            this.serverQty[form.product.value] = form.qty.value
        })
        this.productForms.forEach((form) => {
            this.serverCustomerActive[form.product.value] =
                form.customerActive.value
        })

        this.currentQty = { ...this.serverQty }
        this.expectedServerQty = { ...this.serverQty }

        this.currentCustomerActive = { ...this.serverCustomerActive }
        this.expectedCustomerActive = { ...this.serverCustomerActive }

        this.proceedBtn = document.querySelector('.btn-proceed-checkout')

        this.priceTags = document.querySelectorAll(
            '.cart-table__subtotal .cart-price .price'
        )

        this.total = document.querySelector(
            '.cart-totals__value strong .price, .total__value .price'
        )

        this.discount = document.querySelector(
            '.cart-totals__value strong .price, .total_discount__value .price'
        )

        this.totalTop = document.querySelector(
            '.cart-summary-top-total__price .price'
        )
        this.totalCount = document.querySelector(
            '.cart-summary-top-count .count'
        )

        this.removeSelectedLi = document.querySelector(
            '[data-remove="selected"]'
        ).parentElement

        this.removeUnselectedLi = document.querySelector(
            '[data-remove="unselected"]'
        ).parentElement

        this.qtyFormInputs.forEach((i) => {
            i.addEventListener(
                'qty-change',
                () => {
                    this.resetErrorStatuses()

                    this.currentQty[i.dataset.id] = i.value

                    this.updateTotalCounter()
                    if (!this.changedProducts.length && !this.serverIsBusy) {
                        this.setPlaceholders(false)
                    }
                    this.debouncedUpdate(mainForm)
                },
                false
            )
        })
        this.customerActiveCheckbox.forEach((i) => {
            i.addEventListener(
                'change',
                () => {
                    this.resetErrorStatuses()

                    const currentProductRow = this.getProductRow(i.dataset.id)
                    this.currentCustomerActive[i.dataset.id] = Boolean(
                        i.checked
                    )
                    this.updateTotalCounter()
                    this.debouncedUpdate(mainForm)
                    if (!this.currentCustomerActive[i.dataset.id]) {
                        currentProductRow.classList.add('cart-item--inactive')
                    } else {
                        currentProductRow.classList.remove(
                            'cart-item--inactive'
                        )
                    }

                    this.showRemoveOptions()
                },
                false
            )
            if (!i.checked) {
                this.customerActiveAllCheckbox.checked = false
            }
        })
        this.customerActiveAllCheckbox.addEventListener(
            'change',
            () => {
                this.customerActiveCheckbox.forEach((i) => {
                    i.checked = this.customerActiveAllCheckbox.checked
                    if (
                        Boolean(i.checked) !==
                        this.currentCustomerActive[i.dataset.id]
                    ) {
                        i.dispatchEvent(new Event('change'))
                    }
                })
            },
            false
        )

        this.showRemoveOptions()
    },
    methods: {
        showRemoveOptions() {
            this.selectedProduct = Array.from(this.customerActiveCheckbox)
                .filter((i) => i.checked)
                .map((i) => i.dataset.itemid)

            const selectedCount = this.selectedProduct.length
            const totalCount = this.customerActiveCheckbox.length

            this.removeSelectedLi.style.display =
                selectedCount > 0 ? 'block' : 'none'
            this.removeUnselectedLi.style.display =
                selectedCount < totalCount ? 'block' : 'none'
        },

        updateTotalCounter() {
            const arrayOfQtyInputs = Array.from(this.qtyFormInputs)
            const headerCounter = document.querySelector(
                '.cart-summary-top-count strong'
            )

            headerCounter.innerHTML = arrayOfQtyInputs
                .filter((input) => {
                    return Boolean(this.currentCustomerActive[input.dataset.id])
                })
                .map((input) => input.value)
                .reduce((count, qty) => Number(count) + Number(qty), 0)
        },

        sendRequest(req) {
            this.serverIsBusy = true
            this.setPlaceholders(true)
            axios({
                method: 'post',
                url: `${BASE_URL}ajaxcart/index/ajaxUpdateQuotes/isAjax/1`,
                data: req
            })
                .then(({ data }) => {
                    this.resetError() // remove global error alert
                    this.updateCartCalculations(
                        data.cart,
                        data.products_updated,
                        data.errors,
                        req.products
                    )

                    this.saveData(data.cart) // update mini cart
                })
                .catch(() => {
                    this.sendErrorsToGtm({
                        error: true,
                        order_data: {
                            message:
                                'Wystąpił błąd podczas aktualizowania koszyka. Odśwież stronę i spróbuj ponownie.'
                        }
                    })

                    this.displayError(
                        'Wystąpił błąd podczas aktualizowania koszyka. Odśwież stronę i spróbuj ponownie.'
                    )

                    // Restore old qtys
                    req.products.forEach((item) => {
                        const oldQty = this.serverQty[item.product]
                        this.setCurrentQty(oldQty, item.product)
                    })
                })
                .finally(() => {
                    this.serverIsBusy = false
                    this.setPlaceholders(false)

                    if (this.requestsToSend.length) {
                        this.sendRequest(this.requestsToSend.shift())
                    }
                })
        },

        getProductRow(productId) {
            return document
                .querySelector(`#product-forms [data-id="${productId}"]`)
                .closest('.cart-item')
        },

        setCurrentQty(qty, productId) {
            this.currentQty[productId] = String(qty)

            const productRow = this.getProductRow(productId)
            const minusButton = productRow.querySelector(
                '[data-qtyForm-btn="down"]'
            )

            const qtyForm = productRow.querySelector('.qty-form input.qty')
                ? productRow.querySelector('.qty-form input.qty')
                : null
            if (qtyForm) {
                qtyForm.value = qty
                productRow.querySelector('[data-qtyform-input]').innerText = qty
            }

            if (1 === parseInt(qty) && minusButton !== null) {
                minusButton.querySelector('span').classList.remove('down-btn')
                minusButton
                    .querySelector('span')
                    .classList.add('icon-remove-from-cart')
            } else if (1 < parseInt(qty) && minusButton !== null) {
                minusButton
                    .querySelector('span')
                    .classList.remove('icon-remove-from-cart')
                minusButton.querySelector('span').classList.add('down-btn')
            }
        },

        UpdateCartQuotes(form) {
            if (!this.changedProducts.length) {
                return
            }

            const products = [...this.changedProducts]
            const requestObject = {
                isAjax: 1,
                form_key: form['form_key'].value,
                products: [...products]
            }

            if (products.length) {
                this.requestsToSend.push(requestObject)

                /* We presume that this change will be hanlded by the server,
                   so next productsChange payload will be compared to expectedServerQty and expectedCustomerActive */
                products.forEach((item) => {
                    this.expectedServerQty[item.product] = item.qty
                    this.expectedCustomerActive[item.product] =
                        item.customerActive
                })

                if (!this.serverIsBusy) {
                    this.sendRequest(this.requestsToSend.shift())
                }
            }
        },

        setPlaceholders(opt = false) {
            this.isLoading = opt
        },

        resetErrorStatuses() {
            this.productForms.forEach((form) => {
                const productRow = form.closest('div')

                if (productRow.classList.contains('has-error')) {
                    productRow.classList.remove('has-error')
                    productRow.querySelector('.single-error-msg').innerHTML = ''
                }
            })
        },

        updateCartCalculations(cart, productsObj, errors, productsToReset) {
            this.total.innerHTML = cart.total
            this.totalTop.innerHTML = cart.total
            if (this.discount) {
                this.discount.innerHTML = cart.discount
            }

            const products = Object.keys(productsObj)

            if (errors.length) {
                this.displayError(errors.join('<br>'))

                errors.forEach((error) => {
                    this.sendErrorsToGtm({
                        error: true,
                        order_data: {
                            message: error
                        }
                    })
                })
            }

            if (products && products.length) {
                products.forEach((productId) => {
                    const { price, qty, errors } = productsObj[productId]

                    this.serverQty[productId] = String(qty)

                    const productRow = this.getProductRow(productId)

                    const priceElement = productRow.querySelector(
                        '.cart-table__subtotal .price'
                    )
                        ? productRow.querySelector(
                              '.cart-table__subtotal .price'
                          )
                        : null

                    if (priceElement) {
                        priceElement.innerHTML = price
                    }

                    if (errors.length) {
                        errors.forEach((error) => {
                            this.sendErrorsToGtm({
                                errors: [
                                    {
                                        sku: productId,
                                        message: error
                                    }
                                ]
                            })
                        })
                        this.setErrorFrame(productRow).innerHTML = errors.join(
                            '<br>'
                        )

                        this.setCurrentQty(qty, productId)
                    }
                })
            }
            if (productsToReset && productsToReset.length) {
                productsToReset.forEach((item) => {
                    const productRow = this.getProductRow(item.product)
                    this.setErrorFrame(productRow)
                    // correct to right estimate
                    this.expectedServerQty[item.product] = this.serverQty[
                        item.product
                    ]
                    this.expectedCustomerActive[
                        item.product
                    ] = this.serverCustomerActive[item.product]

                    this.setCurrentQty(
                        this.serverQty[item.product],
                        item.product
                    )
                })
            }
        },
        setErrorFrame(productRow) {
            productRow.classList.add('has-error')
            const errorEl = productRow.querySelector('.single-error-msg')
            //firefox fix
            errorEl.style.width = `${productRow.clientWidth}px`
            return errorEl
        }
    },

    render() {
        return null
    }
}
</script>
<style lang="scss">
.cart {
    &-table__subtotal,
    &-table__grand,
    &-summary-top-count,
    &-summary-top-total__price {
        .line-placeholder {
            color: rgba(0, 0, 0, 0);
            display: inline-block;
            border-radius: 4px;
            animation-duration: 1.8s;
            animation-fill-mode: forwards;
            animation-iteration-count: infinite;
            animation-name: placeHolderShimmer;
            animation-timing-function: linear;
            background: #c6c8ca;
            background: linear-gradient(
                to right,
                #dbd9d9 8%,
                #bdbaba 38%,
                #dbd9d9 54%
            );
            background-size: 1000px 640px;
            position: relative;
            line-height: 28px;
        }

        &-totals,
        &-summary-top-count,
        &-summary-top-total__price {
            .price {
                width: 90px;
            }
            .line-placeholder--total {
                width: 100%;
                height: 28px;
                color: rgba(0, 0, 0, 0);
            }
        }
    }

    @keyframes placeHolderShimmer {
        0% {
            background-position: -468px 0;
        }

        100% {
            background-position: 468px 0;
        }
    }
}
</style>
